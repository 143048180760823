import React from "react";
import ReactDOM from "react-dom";
import styled, { css, createGlobalStyle } from "styled-components";

import "./styles.css";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Gotham";
    src: url("/Gotham-Medium.woff2") format("woff2"),
      url("/Gotham-Medium.woff") format("woff");
    font-style: normal;
  }

  @font-face {
    font-family: "Gotham";
    src: url("/Gotham-Bold.woff2") format("woff2"),
      url("/Gotham-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Medium.woff2') format('woff2'),
        url('Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Bold.woff2') format('woff2'),
        url('Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  :root {
    ${({ primary }) =>
      css`
        --primary: ${primary || "#002B55"};
      `}
    ${({ secondary }) =>
      css`
        --secondary: ${secondary || "white"};
      `}
  }
  body {
    font-family: 'Montserrat', sans-serif;
    background: var(--primary);
    // font-family: sans-serif;
    // text-transform: uppercase;
    color: var(--secondary);
    position: relative;
    margin: 0;
  }
  button, p, div {
    user-select: none;
  }
  #root {
    height: 100%;
  }
`;

// const colors = ["#092A60", "#076D78", "#31A97B", "#EACA6C", "#D96441"];
const heroes = [
  { image: "/img/hero0.png", primary: "#ff0000", secondary: "white" },
  { image: "/img/hero1.png", primary: "#ff0068", secondary: "white" },
  { image: "/img/hero2.png", primary: "#d900ff", secondary: "white" },
  { image: "/img/hero3.png", primary: "#0019ff", secondary: "white" },
  { image: "/img/hero4.png", primary: "#00e7ff", secondary: "white" },
  { image: "/img/hero5.png", primary: "#00ff36", secondary: "white" },
  { image: "/img/hero6.png", primary: "#00ffd4", secondary: "white" },
  { image: "/img/hero7.png", primary: "#ffec00", secondary: "white" }
];

const ColorBubble = styled.div`
  border: 2px solid transparent;
  width: 8vh;
  height: 8vh;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
  ${({ selected }) =>
    selected &&
    css`
      border-color: var(--secondary);
    `}
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Input = styled.input`
  -webkit-appearance: none;
  background: transparent;
  border: none;
  border-bottom: 2px solid var(--secondary);
  color: var(--secondary);
  width: 340px;
  font-size: 32px;
  text-align: center;
  outline: none;
  &::-webkit-input-placeholder {
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 200;
    color: #6f6f6f;
  }
`;

const LeetspeakLogo = styled.img`
  width: 200px;
`;

const Text = styled.p`
  font-family: "Montserrat";
  text-align: center;
  font-size: 24px;
  font-weight: bold;
`;

const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: calc(100vh - 140px);
  margin-bottom: 40px;
`;

const Btn = styled.button`
  outline: none;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px solid var(--secondary);
  background: transparent;
  position: relative;
  ${({ rotated }) =>
    rotated &&
    css`
      transform: rotate(180deg);
    `}
  ${({ half }) =>
    half &&
    css`
      transform: rotate(270deg);
    `}
  &::after {
    content: "";
    border: 20px solid transparent;
    border-left: 25px solid white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-20%, -50%);
  }
  ${({ bigger }) =>
    bigger &&
    css`
      width: 35vw;
      height: 35vw;
    `}
  ${({ biggest }) =>
    biggest &&
    css`
      width: 45vw;
      height: 45vw;
    `}
  &:active {
    border-color: var(--primary);
    background: var(--secondary);
    &::after {
      border-left-color: var(--primary);
    }
  }
`;

const SplitContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  & > div {
    flex-grow: 1;
    flex-basis: 0 0 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const BigNumber = styled.div`
  font-size: 300px;
  font-family: "Gotham";
`;

const SmallText = styled.div`
  font-family: "Montserrat";
  font-size: 18px;
  line-height: 1.44;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
`;

const TutorialImage = styled.img`
  height: 150px;
`;

function App() {
  const [ws, setWs] = React.useState(null);
  const [hero, setHero] = React.useState(
    localStorage.getItem("hero") &&
      JSON.parse(localStorage.getItem("hero") || null)
  );
  const [tempHero, setTempHero] = React.useState(null);
  const [name, setName] = React.useState(localStorage.getItem("name") || "");
  const [tutorial, setTutorial] = React.useState(
    localStorage.getItem("tutorial") || false
  );
  const [tempName, setTempName] = React.useState("");
  const [inCountdown, setInCountdown] = React.useState(false);
  const [countdownNumber, setCountdownNumber] = React.useState(0);
  const [inGame, setInGame] = React.useState(false);
  const [queed, setQueed] = React.useState(false);
  const [level, setLevel] = React.useState(0);
  const [won, setWon] = React.useState(false);

  React.useEffect(() => {
    const ws = new WebSocket("wss://leetspeak-tree-socket.herokuapp.com/");

    ws.addEventListener("open", function open() {
      setWs(ws);
    });

    ws.addEventListener("message", async function incoming(data) {
      const msg = JSON.parse(data.data);
      switch (msg.type) {
        case "initiate_countdown": {
          setInCountdown(true);
          setCountdownNumber(5);
          setQueed(false);
          break;
        }
        case "queed": {
          setQueed(true);
          break;
        }
        case "countdown_number": {
          setCountdownNumber(msg.number);
          break;
        }
        case "player_start": {
          setInCountdown(false);
          setInGame(true);
          break;
        }
        case "player_died": {
          setInGame(false);
          setLevel(0);
          break;
        }
        case "level_up": {
          // alert('Level up');
          setLevel(1);
          break;
        }
        case "won": {
          // alert('Level up');
          setWon(true);
          await new Promise(resolve => setTimeout(resolve, 3000));
          setWon(false);
          setInGame(false);
          setLevel(0);
          break;
        }
        default:
          break;
      }
    });
  }, []);
  React.useEffect(() => {
    if (ws && hero && name) {
      ws.send(JSON.stringify({ name, color: hero.primary, type: "subscribe" }));
    }
  }, [ws, hero, name]);
  const move = dir => {
    if (ws) {
      ws.send(JSON.stringify({ type: "move", direction: dir }));
    }
  };

  const gameStart = () => {
    if (ws) {
      ws.send(JSON.stringify({ type: "start" }));
    }
  };

  const fastTap = () => {
    if (ws) {
      ws.send(JSON.stringify({ type: "fasttap" }));
    }
  };

  const screens = [
    {
      condition: won,
      content: (
        <SplitContainer>
          <div>
            <Text>
              <span role="img" aria-label="Woho">
                🎉{" "}
              </span>
              <br />
              Congratulations,
              <br /> you won!
              <br />
              <span role="img" aria-label="Woho">
                🎉{" "}
              </span>
            </Text>
          </div>
        </SplitContainer>
      )
    },
    {
      condition: level === 1 && inGame,
      content: (
        <SplitContainer>
          <div>
            <Text>Let’s tap as fast as you can now {name}!</Text>
          </div>
          <div />
          <div>
            <Btn biggest half onClick={fastTap} />
          </div>
        </SplitContainer>
      )
    },
    {
      condition: level === 0 && inGame,
      content: (
        <SplitContainer>
          <div>
            <Text>Avoid white dots!</Text>
          </div>
          <div />
          <div>
            <Row>
              <Btn bigger rotated onClick={() => move("left")} />
              <Btn bigger onClick={() => move("right")} />
            </Row>
          </div>
        </SplitContainer>
      )
    },
    {
      condition: queed,
      content: (
        <SplitContainer>
          <div>
            <Text>Are your ready {name}?</Text>
          </div>
          <div>
            <BigNumber>5</BigNumber>
          </div>
          <div>
            <SmallText>
              Please wait for available spot. Game will start after countdown
            </SmallText>
          </div>
        </SplitContainer>
      )
    },
    {
      condition: inCountdown,
      content: (
        <SplitContainer>
          <div>
            <Text>Are your ready {name}?</Text>
          </div>
          <div>
            <BigNumber>{countdownNumber}</BigNumber>
          </div>
        </SplitContainer>
      )
    },
    {
      condition: hero && name && tutorial,
      content: (
        <SplitContainer>
          <div>
            <Text>Are your ready {name}?</Text>
          </div>
          <div>
            <BigNumber>5</BigNumber>
          </div>
          <div>
            <SmallText>By pressing button you will start countdown</SmallText>
            <Btn onClick={gameStart} />
          </div>
        </SplitContainer>
      )
    },
    {
      condition: name && hero && !tutorial,
      content: (
        <SplitContainer>
          <div>
            <Text>How to play.</Text>
          </div>
          <div>
            <TutorialImage src="/tutorial1.png" />
            <SmallText>
              Move LEDs left and right with the buttons to avoid white LEDs.
            </SmallText>
          </div>
          <div>
            <TutorialImage src="/tutorial2.png" />
            <SmallText>
              Show us your ninja skills and tap as fast as you can to achieve
              the top.
            </SmallText>
          </div>
          <div>
            <Btn
              onClick={() => {
                localStorage.setItem("tutorial", JSON.stringify(true));
                setTutorial(true);
              }}
            />
          </div>
        </SplitContainer>
      )
    },
    {
      condition: name && !hero,
      content: (
        <SplitContainer>
          <div>
            <h1>Pick ninja style</h1>
          </div>
          <div>
            {[[0, 1], [2, 3], [4, 5], [6, 7]].map(nr => (
              <Row>
                {nr.map(nr => {
                  let h = heroes[nr];
                  return (
                    <ColorBubble
                      key={h.image}
                      style={{ backgroundImage: `url(${h.image})` }}
                      selected={h === tempHero}
                      onClick={() => h !== hero && setTempHero(h)}
                    />
                  );
                })}
              </Row>
            ))}
          </div>
          <div>
            <Btn
              onClick={() => {
                localStorage.setItem("hero", JSON.stringify(tempHero));
                setHero(tempHero);
              }}
            />
          </div>
        </SplitContainer>
      )
    },
    {
      condition: !name,
      content: (
        <SplitContainer>
          <div>
            <LeetspeakLogo src="/leetspeak.svg" />
          </div>
          <div>
            <Text>Pick a name</Text>
            <Input
              value={tempName}
              onChange={e => setTempName(e.target.value)}
              placeholder="Capitain ninja"
            />
          </div>
          <div>
            <Btn
              onClick={() => {
                localStorage.setItem("name", tempName);
                setName(tempName);
              }}
            />
          </div>
        </SplitContainer>
      )
    }
  ];

  const pickedScreen = screens.find(({ condition }) => condition) || {
    content: null
  };

  return (
    <Container>
      <GlobalStyle
        primary={(hero || tempHero || {}).primary}
        secondary={(hero || tempHero || {}).secondary}
      />
      {pickedScreen.content}
    </Container>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
